body {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.live-indicator {
  display: inline-block;
  background-color: white; /* Fondo blanco */
  color: red; /* Texto rojo */
  padding: 5px 10px;
  border: 2px solid red; /* Borde rojo de 2px */
  border-radius: 4px;
  animation: pulse 0.8s infinite alternate;
  margin: 0 10px; /* Márgenes laterales de 10px */
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}


.button_style {
  margin-left: 10px !important;
  margin-right: 10px;
}

.centerTable {
  margin: 0px auto;
}

h2 {
    color: #2a3051;
}
h3 {
  color: #2a3051;
}

.title {
  color: #9e3e07;
} 
/* Estilos para el contenedor principal del formulario */
/* Estilos para el contenedor principal del formulario */
.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  
}
.particles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* 100% del viewport height */
  z-index: -1;
  background-color: #272727;

}



/* Estilos para el contenedor del logo y el título */
.logo-container {
  text-align: center;
  margin-bottom: 1px;
}

/* Estilos para el logo */
.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilos para el título */
.title {
  font-size: 24px;
  color: #ececec;
  margin-top: 10px;
}

.footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  color: #f0f0f0;
}

.footer2 {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  color: #343434;
}

/* Estilos para el contenedor del formulario */
.form-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilos para el enlace de registro */
.register-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}
