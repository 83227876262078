@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");






/* Estilos base */
.App {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; } /* Cambiado de 5 a 1 */
}

@keyframes rotate-sun {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.highlight-enter {
  background-color: yellow;
}

.highlight-enter-active {
  background-color: inherit;
  transition: background-color 2s ease;
}

.highlight-exit {
  background-color: inherit;
}

.highlight-exit-active {
  background-color: yellow;
  transition: background-color 2s ease;
}
